<template>
  <div>
    <div>
      <el-row
        style="padding-bottom: 15px"
        type="flex"
        align="middle"
        justify="space-between"
      >
        <el-col :span="3">
          <b>已审核培训</b>
        </el-col>
        <el-col :span="2"  > 培训名称: </el-col>
        <el-col :span="4" :push="3">
          <el-input
            placeholder="输入培训名称可进行搜索"
            v-model="searchTitle"
            clearable
            size="small"
          >
          </el-input>
        </el-col>
        <el-col :span="5" :pull=1>
          <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">搜索</el-button>
          <el-button  size="small"  @click="clearInput">重置</el-button>
        </el-col>
      </el-row>

      <el-table
        v-loading="loading"
        element-loading-text="加载中"
        ref="multipleTable"
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{ background: '#eee', color: '#333' }"
        :default-sort = "{prop: 'updateTime', order: 'descending'}"
        >

        <el-table-column prop="updateTime" label="审核日期" align="center" width="140px"></el-table-column>
<!--        <el-table-column prop="createTime" label="申请日期" align="center" width="140"></el-table-column>-->
<!--        <el-table-column prop="startTime" label="开始时间" align="center"width="140"></el-table-column>-->
        <el-table-column prop="title" label="培训主题" align="center" ></el-table-column>

        <!--      <el-table-column prop="desc" label="培训介绍" align="center"></el-table-column>-->
        <!--      <el-table-column prop="host" label="主持人" align="center"></el-table-column>-->
        <!--      <el-table-column prop="address" label="培训地点" align="center"></el-table-column>-->
        <!--      <el-table-column prop="memo" label="其他要求" align="center"></el-table-column>-->

        <el-table-column prop="dname" label="主办方" align="center" width="200"></el-table-column>
<!--        <el-table-column prop="contacter" label="联系人" align="center" width="150"></el-table-column>-->
<!--        <el-table-column prop="phone" label="联系电话" align="center"  width="150"></el-table-column>-->

        <!--      <el-table-column prop="end_time" label="结束时间" align="center"></el-table-column>-->
<!--        <el-table-column prop="consumer" label="培训对象" align="center" width="120"></el-table-column>-->
        <el-table-column prop="type" label="培训类型" align="center" width="110"></el-table-column>
<!--        <el-table-column prop="score" label="分数" align="center" width="80"></el-table-column>-->
        <!--      <el-table-column prop="enrollment" label="最大人数" align="center"></el-table-column>-->
        <el-table-column prop="status" label="审核状态" align="center" width="100"></el-table-column>
        <el-table-column prop="info" label="审核意见" align="center"></el-table-column>
        <el-table-column prop="aname" label="审核人" align="center" width="110"></el-table-column>
        <!--      <el-table-column prop="sign_code" label="签到二维码" align="center"></el-table-column>-->
        <!--      <el-table-column prop="out_code" label="签退二维码" align="center"></el-table-column>-->


        <el-table-column label="操作" align="center" width="100px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-more"
              @click="handleDeatil(scope.row)">
              详情
            </el-button>

          </template>
        </el-table-column>
      </el-table>

      <!--      分页插件-->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        :page-size="10"
        :total="total"
        style="margin-top: 20px; text-align: right">
      </el-pagination>
    </div>
    <!--表格-->
    <el-dialog
      :fullscreen="true"
      title="培训详情"
      :visible.sync="detailDialogVisible"
      :show-close=true
      width="60%"
    >
      <div>
        <el-row>
          <el-col :span="12">
            <h3>培训ID</h3>
            <span>{{currentData.id}}</span>
          </el-col>
          <el-col :span="12">
            <h3>培训主题</h3>
            <span>{{currentData.title}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="12">
            <h3>培训介绍</h3>
            <span>{{currentData.content}}</span>
          </el-col>
          <el-col :span="12">
            <h3>培训学时</h3>
            <span>{{currentData.score}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="12">
            <h3>主讲嘉宾</h3>
            <span>{{currentData.host}}</span>
          </el-col>
          <el-col :span="12">
            <h3>最多可报名人数</h3>
            <span>{{currentData.enrollment}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="12">
            <h3>主办方</h3>
            <span>{{currentData.dname}}</span>
          </el-col>
          <el-col :span="12">
            <h3>培训群体</h3>
            <span>{{currentData.consumer}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="12">
            <h3>培训时间</h3>
            <span>{{currentData.startTime}} - </span> <span>{{currentData.endTime}}</span>
          </el-col>
          <el-col :span="12">
            <h3>培训地点</h3>
            <span>{{currentData.addressTemp}}</span>

          </el-col>
        </el-row>

        <el-divider></el-divider>

        <h3>培训要求</h3>
        <span>{{currentData.memo}}</span>

      </div>

<!--      <span slot="footer" class="dialog-footer">-->
<!--    <el-button @click="detailDialogVisible = false">关闭</el-button>-->
<!--  </span>-->
    </el-dialog>

  </div>
</template>

<script>
  import { request } from '@/network/network'
  import {train_status_dict, train_consumer_dict,train_type_dict,train_status_options, train_consumer_options } from '@/utils/dict'

  export default {
    data() {
      return {
        loading: true,
        total: 0,
        currentPage: 0,
        searchTitle:"",
        searchMap:"",
        detailDialogVisible:false,
        currentData:[],
        tableData: [],

      };
    },
    methods: {
      //分页插件绑定事件
      handleCurrentChange(val) {
        this.loading = true
        this.currentPage = val
        this.getTrains(this.searchMap ,val);
      },
      //搜索按钮
      handleSearch(){
        this.searchMap = this.searchTitle
        this.getTrains(this.searchMap);

      },

      //重置搜索框
      clearInput() {
        this.searchTitle = "";
        this.searchMap = "",
          this.getTrains()
      },
      //点击查看详情按钮
      handleDeatil(row){
        this.detailDialogVisible = true
        this.currentData = row
        let map = JSON.parse(row.mapAddress)
        this.currentData.addressTemp = map.district +' - '+ map.address+' - ' + map.name +' ('+this.currentData.address+')'

      },

      getTrains(title, page){
        return request({
          url: '/train/trains',
          method:'get',
          params:{
            title:title,
            page: page,
            status: 1,
            dtype: 0
          }
        }).then(res => {
          this.total = res.data.total
          let temp = res.data.list
          this.tableData = temp.map(item => {
            if (item.info == null || item.info == ''){item.info = '-'}
            item.status = train_status_dict[item.status];
            item.consumer =  train_consumer_dict[item.consumer];
            // item.startTime = formatDate(item.startTime)
            // item.endTime = formatDate(item.endTime)
            // item.createTime = formatDate(item.createTime)
            // item.updateTime = formatDate(item.updateTime)
            item.type = train_type_dict[item.type]
            return item
          })
          this.loading = false
        })
      },
    },
    created() {
      this.getTrains();
    }
  }
</script>

<style scoped>
  .input-width {
    width: 80%;
  }

</style>
